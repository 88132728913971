import(/* webpackMode: "eager" */ "/app/app/global.css");
;
import(/* webpackMode: "eager", webpackExports: ["RouterTransition"] */ "/app/components/RouterTransition.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["ColorSchemeScript"] */ "/app/node_modules/@mantine/core/esm/core/MantineProvider/ColorSchemeScript/ColorSchemeScript.mjs");
;
import(/* webpackMode: "eager", webpackExports: ["MantineProvider"] */ "/app/node_modules/@mantine/core/esm/core/MantineProvider/MantineProvider.mjs");
;
import(/* webpackMode: "eager" */ "/app/node_modules/@mantine/core/styles.css");
;
import(/* webpackMode: "eager" */ "/app/node_modules/@mantine/dropzone/styles.css");
;
import(/* webpackMode: "eager", webpackExports: ["closeAllModals","closeModal","modals","openConfirmModal","openContextModal","openModal"] */ "/app/node_modules/@mantine/modals/esm/events.mjs");
;
import(/* webpackMode: "eager", webpackExports: ["ModalsProvider"] */ "/app/node_modules/@mantine/modals/esm/ModalsProvider.mjs");
;
import(/* webpackMode: "eager", webpackExports: ["useModals"] */ "/app/node_modules/@mantine/modals/esm/use-modals/use-modals.mjs");
;
import(/* webpackMode: "eager" */ "/app/node_modules/@mantine/nprogress/styles.css");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/node_modules/next-intl/dist/esm/shared/NextIntlClientProvider.js");
;
import(/* webpackMode: "eager", webpackExports: ["theme"] */ "/app/theme.ts");
